<template>
  <view-wrapper>
    <template #header>
      <div class="flex items-center w-full">
        <img v-if="store.state.user.icon" class="w-[72px] h-[72px] rounded-1/2" :src="store.state.user.icon" />
        <img v-else class="w-[72px] h-[72px] rounded-1/2" src="@supplier/assets/avatar.png" alt="" />
        <div class="flex flex-col h-full ml-20px">
          <span class="text-20px font-500">你好, {{ store.state.user.name }}</span>
          <span class="mt-10px text-14px font-normal text-gray-500" v-if="isAuthenticated"
            >管理员 | {{ store.state.user.supplier?.shortName }}</span
          >
        </div>
        <div class="flex flex-auto flex-col" v-if="isAuthenticated">
          <span class="text-right text-14px font-normal text-gray-500">粉丝数量</span>
          <span class="text-right text-30px font-500 mt-10px">{{ store.state.supplier.followerCount || 0 }}</span>
        </div>
      </div>
    </template>

    <component :is="isAuthenticated ? Authenticated : UnAuthenticated"></component>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useStore } from '@vue-mfe/utils'

import Authenticated from '@supplier/components/authenticated.vue'
import UnAuthenticated from '@supplier/components/un-authenticated.vue'

const store = useStore()
store.actions.changeSupplier()

const isAuthenticated = computed(() => ['A', 'D'].includes(store.state.supplier.status.code))
</script>
